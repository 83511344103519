import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.png';
import logoWhite from './logoWhite.png'
import './App.css';
import serversData from './servers.js';

function App() {
  const [servers, setServers] = useState(serversData);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [displayedPlayers, setDisplayedPlayers] = useState(0);

  useEffect(() => {
    const fetchPlayerCounts = async () => {
      const updatedServers = await Promise.all(
        servers.map(async (server) => {
          try {
            const response = await fetch(`https://api.mcstatus.io/v2/status/java/${server.ip}`);
            const data = await response.json();
            return { ...server, onlinePlayers: data.players.online };
          } catch (error) {
            console.error(`Error fetching player count for ${server.name}:`, error);
            return { ...server, onlinePlayers: 0 };
          }
        })
      );
      setServers(updatedServers);
      const total = updatedServers.reduce((sum, server) => sum + server.onlinePlayers, 0);
      setTotalPlayers(total);
    };

    fetchPlayerCounts();
  }, []); // Empty dependency array, as we only want to run this once on mount

  useEffect(() => {
    if (totalPlayers > 0) {
      const animationDuration = 2000; // 2 saniye
      const frameDuration = 1000 / 60; // 60 FPS
      const totalFrames = animationDuration / frameDuration;
      const incrementPerFrame = totalPlayers / totalFrames;

      let frame = 0;
      const counter = setInterval(() => {
        frame++;
        setDisplayedPlayers(Math.min(Math.floor(incrementPerFrame * frame), totalPlayers));

        if (frame >= totalFrames) {
          clearInterval(counter);
          setDisplayedPlayers(totalPlayers);
        }
      }, frameDuration);

      return () => clearInterval(counter);
    }
  }, [totalPlayers]);

  const serversRef = useRef(null);

  const scrollToServers = () => {
    serversRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <div className="navbar">
        <img src={logo} alt="logo" />
      </div>

      <div className="hero container">
        <div className="hero-bg">
          <h2>WHERE YOUR GAMING JOURNEY BEGINS!</h2>
          <h1>Join <span>{displayedPlayers}</span> Players Currently In-Game</h1>
          <button onClick={scrollToServers}>VIEW SERVERS</button>
        </div>
      </div>

      <div className="aboutUs container">
        <h2>WHO ARE WE?</h2>
        <p>Our Minecraft network offers a variety of servers to cater to all types of players. Whether you enjoy survival, creative building, mini-games, or role-playing, we have a server for you. Our community is friendly and welcoming, and our staff is always ready to help. Join us today and become part of our growing family!</p>
      </div>

      <div id="servers" className="servers container" ref={serversRef}>
        <h2>OUR SERVERS</h2>

        <div className="server-cards">
          {servers.map((server, index) => (
            <div className="server-card" key={index}>
              <div className="server-img-bg" style={{ backgroundColor: server.logoBgColor }}>
                <img src={require(`/${server.logo}`)} alt={`${server.name} logo`} />
              </div>
              <div className="server-info">
                <div className="server-header">
                  <h3>{server.name}</h3>
                  <div className="server-social">
                    <a href={server.social.discord} target="_blank" rel="noopener noreferrer">
                      <i className="fa-brands fa-discord" style={{color: "#443698", backgroundColor: "#ABA5D1"}}></i>
                    </a>
                    <a href={server.social.instagram} target="_blank" rel="noopener noreferrer">
                      <i className="fa-brands fa-instagram" style={{color: "#c13584", backgroundColor: "#E2A2C6"}}></i>
                    </a>
                  </div>
                </div>
                <p>{server.description}</p>
                <div className="server-footer">
                  <a href={server.webSite}>
                    <button style={{ backgroundColor: server.buttonColor, color: server.textColor }}>PLAY NOW</button>
                  </a>
                  <div className="server-players"><span>{server.onlinePlayers}</span> Players</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <footer className="footer">
        <div className="footer-content container">
          <div className="footer-logo">
            <img src={logoWhite} alt="CraftLime logo" />
          </div>
          <div className="footer-links">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/servers">Servers</a></li>
            </ul>
          </div>
          <div className="footer-social">
            <a href="https://discord.gg/lime" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-discord"></i>
            </a>
            <a href="https://instagram.com/craftlimenw" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://youtube.com/@craftlimen" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 LimeNW. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
